import {
    SiCplusplus,
    SiCss3,
    SiFigma,
    SiFramer,
    SiGithub, 
    SiHtml5, 
    SiJavascript, 
    SiNeovim,
    SiNextdotjs,
    SiReact,
    SiRust,
    SiTailwindcss,
    SiTypescript,
    SiZig
} from "react-icons/si";
import { IconType } from "react-icons";

export type ProjectProps = {
    id: number;
    name: string;
    description: string;
    technologies: IconType[];
    techNames: string[];
    techLinks: string[];
    github: string;
    demo: string;
    image: string;
    available: boolean;
    imageStyle?: React.CSSProperties; //  Ensured optional image styling support
};

export const projects: ProjectProps[] = [
    {
        id: 0,
        name: "Coming Soon",
        description: "Exciting new project launching soon!",
        technologies: [SiReact, SiNextdotjs, SiTailwindcss], // Example tech stack
        techNames: ["React", "Next.js", "Tailwind CSS"],
        techLinks: [
            "https://reactjs.org/",
            "https://nextjs.org/",
            "https://tailwindcss.com/"
        ],
        github: "https://github.com/your-repo",
        demo: "https://your-demo-link.com",
        image: "/projects/pepe.webp",
        available: true, //  Ensures "See Project" button is displayed
        imageStyle: { objectFit: "cover", borderRadius: "10px" } //  Example styling
    },
    {
        id: 1,
        name: "Another Project",
        description: "A sample project description goes here.",
        technologies: [SiJavascript, SiTypescript, SiHtml5, SiCss3],
        techNames: ["JavaScript", "TypeScript", "HTML5", "CSS3"],
        techLinks: [
            "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
            "https://www.typescriptlang.org/",
            "https://developer.mozilla.org/en-US/docs/Web/HTML",
            "https://developer.mozilla.org/en-US/docs/Web/CSS"
        ],
        github: "https://github.com/your-other-repo",
        demo: "https://your-other-demo.com",
        image: "/projects/sample.webp",
        available: false, //  No "See Project" button if unavailable
        imageStyle: { objectFit: "contain", borderRadius: "5px" }
    }
];
